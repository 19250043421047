@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    height: 100%;
    position: relative;
    color: #eee;
    background-color: #000;
    font-family: "Roboto", Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
}

#root {
    width: 80%;
    margin: 0 auto;
}

@media only screen and (max-width: 785px) {
    #full-nav {
        display: none;
    }

    #mobile-nav {
        display: block;
    }
}

@media only screen and (min-width: 786px) {
    #full-nav {
        display: -webkit-flex;
        display: flex;
    }

    #mobile-nav {
        display: none;
    }
}

.white {
    color: #eee;
}

section.toggle {
    position: relative;
}

article {
    margin: 0 1.5rem 1.5rem 1.5rem;
}

article.showLess {
    height: auto;
    max-height: 2.5em;
    overflow: hidden;
    transition: max-height 800ms linear;
}

article.showMore {
    max-height: 300em;
    transition: max-height 800ms linear;
    overflow: hidden;
}

span.toggleButton {
    border: 0;
    background-color: transparent;
    color: #fff;
    height: 1em;
    margin-left: 1.5rem;
    position: absolute;
    bottom: -0.75em;
    cursor: pointer;
}

nav {
    width: 100%;
    margin: 0 auto;
    background-color: #000000aa;
    color: white;
    font-family: "Roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    height: 59px;
    opacity: 0.75;
    vertical-align: middle;
    display: -webkit-flex;
    display: flex;
}

#mobile-nav-container {
    display: grid;
    grid-template-columns: 4fr auto 1fr;
    height: 59px;
}

#mobile-logo {
    -webkit-align-self: center;
            align-self: center;
}
.full-width {
    width: 100%;
    height: auto;
}

.nav-caption {
    width: 650px;
    height: auto;
    /*text-align: center; */
    padding: 8px 0;
    display: grid;
    grid-template-columns: 1fr;
    align-self: center;
}

.nav-logo {
    justify-self: center;
}

.nav-links {
    font-size: 18px;
    display: grid;
    grid-template-columns: 50fr 2fr 2fr 2fr 2fr 0fr;
    align-self: center;
    justify-items: end;
    grid-column-gap: 2%;
}

.nav-links a {
    color: white;
    text-decoration: none;
}

.content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    margin: -150px auto 5px;
}

.column {
    min-width: 200px;
    width: 18%;
    margin: 0 20px 20px 20px;
    height: 250px;
    background-color: #eee;
    color: black;
    padding: 20px;
}

.title {
    font-size: 20px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 700px) {
    #root {
        width: 100%;
    }

    .title {
        font-size: 14px;
    }

    .content {
        margin: -50px 0 5px;
    }

    .column {
        min-width: 150px;
        height: 300px;
    }
}

@media only screen and (max-width: 460px) {
    .title {
        font-size: 12px;
    }

    .column {
        min-width: 100px;
        height: 350px;
    }

    .content {
        margin: -25px auto 5px;
    }

    #root {
        width: 100%;
    }
}

.react-icon {
    margin: 20px 0 20px 0;
    font-size: 3em;
    border-radius: 100%;
    background-color: #8f07ac;
    padding: 8px;
}

.highlight {
    color: #8f07ac;
}

.oblique {
    font-style: oblique;
}

.bold {
    font-weight: 700;
}

#slider-1 {
    display: block;
}

#slider-2 {
    display: block;
}

#slider-3 {
    display: block;
}

.mobile-logo {
    -webkit-align-self: center;
            align-self: center;
    padding-left: 4%;
}

#mobile-button {
    background-color: transparent;
    color: #8f07ac;
    border: 0;
}

#mobile-links {
    display: none;
    background-color: #000000aa;
    color: white;
    font-family: "Roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
}

.nav-link {
    color: white;
    display: block;
    padding-left: 4%;
    text-decoration: none;
}

#game-tiles img:hover {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}

.flex-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
}
.flex-container img {
    width: 100%;
}
.flex-container > * {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
}

.fade-in {
    will-change: transform, opacity;
    -webkit-animation: reveal 1s ease-out;
            animation: reveal 1s ease-out;
}

.blur {
    -webkit-filter: blur(1vw);
            filter: blur(1vw);
}

.holder {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    overflow: hidden;
}

#content img {
    margin: 0 auto;
}

#content h1,
#content h2 {
    text-align: center;
    margin: 10px 0;
}

article > p {
    margin-bottom: 10px;
}

.large {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.large img {
    width: 75%;
}

.contact-form {
    width: 80%;
    margin: 0 auto;
}

.divider {
    margin: 10px 0;
}

.label {
    width: 10%;
    display: block;
    padding: 5px;
    margin: 2% 1% 0 2%;
    font-size: 24px;
}

.text-input {
    width: 95%;
    margin: 1% 1% 1% 2%;
    border: 1px solid #8f07ac;
    padding: 5px;
    font-size: 16px;
    display: block;
}

.submit-button {
    border: 1px solid #eee;
    background-color: #8f07ac;
    font-size: 24px;
    margin: 5px auto;
    padding: 5px;
    color: #eee;
    border-radius: 10px;
    display: block;
}

.text-area {
    min-width: 95%;
    margin: 1% 1% 1% 2%;
    border: 1px solid #8f07ac;
    display: block;
    font-size: 16px;
    padding: 5px;
}

.form-send-message {
    font-size: 24px;
    display: none;
}

